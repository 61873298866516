#subMv {
  .mvInr {
    position: relative;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    max-width: 100%;
    width: 100%;
    margin-top: 54px;
    position: relative;
    @include tab {
      background-repeat: no-repeat;
      background-attachment: initial;
      background-position: center center;
    }
  }
}

.lowerPage {
  padding: 120px 0 60px;
}

#wrap {
  margin-left: 100px;
  overflow: hidden;
  @include pc {
    margin-left: 0;
  }
}

#breadCrumbs {
  @include transition;
  margin: 0 auto;
  padding: 10px 5px;
  font-size: 0.92em;
  background-color: #eaeaea;
  @include gothic;
  color: #7b7474;
  a {
    display: inline-block;
    text-decoration: underline;
    font-size: 0.92em;
    @include gothic;
    color: #7b7474;
  }
  i {
    font-size: 120%;
    vertical-align: text-top;
  }
}

#thanks {
  padding-top: 80px;
  @include tab {
    padding-top: 80px;
  }
}

.bscBox01 {
  box-sizing: border-box;
  padding: 30px;
  color: $c01;
  background-color: #fff;
}

.bscBox02 {
  background-color: #fff;
  box-sizing: border-box;
  padding: 2.5em;
  border: 4px solid $c02;
}

.indList,
.indListS {
  margin-bottom: 15px;

  li::before {
    content: "●";
    font-size: 50%;
    margin-right: 5px;
    vertical-align: middle;
    color: $c01;
  }
}

.indList li {
  line-height: 170%;
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 5px;

  @include sp {
    font-size: 3.2vw;
  }
}

.indListS li {
  line-height: 170%;
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 5px;
  font-size: 1.1rem !important;
  line-height: 130%;
}

.numList,
.numListS {
  margin-bottom: 15px;
  padding-left: 1.6em;
}

.numList li {
  line-height: 150%;
  list-style: decimal outside none;
  margin-bottom: 5px;

  @include sp {
    font-size: 3.2vw;
  }
}

.numListS li {
  line-height: 150%;
  list-style: decimal outside none;
  margin-bottom: 5px;
  font-size: 1.1rem !important;
  line-height: 130%;
}

.bscTbl01 table {
  border-collapse: collapse;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 100%;

  th,
  td {
    font-size: 1em;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    line-height: 1.4;
    padding: 20px 20px 17px;
    text-align: left;
  }

  th {
    background-color: #ddd;
    border-bottom: 1px solid #fff;
  }

  tr.head th {
    background-color: $c02;
    color: #fff;
  }

  tr:last-child {
    th {
      border-bottom: 1px solid #ddd;
    }
  }
}

.bscTbl01 + .bscTbl01 {
  margin-top: 3.5em;
}

.bscTbl01.tblScroll {
  table {
    table-layout: fixed;

    th,
    td {
      display: table-cell;
      width: auto;
    }
  }
}

@include tab {
  .tblScroll {
    &::after {
      content: "※フリックで横スクロールができます。";
      display: block;
      font-size: 1em;
      margin-top: 1em;
    }

    .tblScrollInr {
      overflow-x: auto;

      // -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
      }
    }

    table {
      margin-bottom: 0;
      width: 250%;
      table-layout: fixed;

      th,
      td {
        display: table-cell;
        vertical-align: top;
      }
    }
  }
}

.clm4 {
  overflow: hidden;

  li {
    float: left;
    margin-right: 1.3%;
    width: 24%;

    &:nth-child(4n) {
      margin-right: 0;
    }

    + li + li + li + li {
      margin-top: 2em;
    }

    @include sp {
      &.fadeIn {
        transition-delay: 0.3s !important;
      }
    }
  }

  figcaption {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}

.clm3 {
  overflow: hidden;

  li {
    float: left;
    margin-bottom: 20px;
    margin-right: 1.3%;
    width: 32.4%;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include sp {
      &.fadeIn {
        transition-delay: 0.3s !important;
      }
    }
  }

  figcaption {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}

.clm2 {
  overflow: hidden;

  li {
    float: left;
    margin-bottom: 20px;
    width: 48.5%;

    &.even,
    &:nth-child(2n) {
      float: right;
    }

    @include sp {
      &.fadeIn {
        transition-delay: 0.3s !important;
      }
    }
  }

  figcaption {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}

//pager
.pager {
  margin-top: 4em;
  text-align: center;
  a {
    font-size: 20px;
    @include uniq02;
    font-weight: 400;
    background-color: #d5e1e6;
    color: $black;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin: 0 2px;
    padding: 13px 15px 12px;
    text-decoration: none;
    position: relative;
    @include transition;
    @include tab {
      padding: 10px 12px 8px;
    }
    &:hover {
      opacity: 0.8;
    }
    &.current {
      @include gradationBg01;
      color: #fff;
    }
  }
}

#topcontrol {
  display: none;
  z-index: 900;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transform: translateZ(0);

  a {
    background-color: $c01;
    display: block;
    height: 60px;
    width: 60px;
    filter: alpha(opacity=70) !important;
    opacity: 0.7 !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
      filter: alpha(opacity=10) !important;
      opacity: 1 !important;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }

    &::after {
      content: "";
      display: block;
      transform: rotate(-45deg);
      color: #fff;
      width: 12px;
      height: 12px;
      border-top: 4px solid #fff;
      border-right: 4px solid #fff;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 26px;
      left: 22px;
      border-radius: 2px;
    }
  }
  @include pc {
    bottom: 10px !important;
    right: 10px !important;
    height: 40px;
    width: 40px;
    a {
      width: 40px;
      height: 40px;
      &::after {
        top: 18px;
        left: 14px;
        border-width: 2px;
      }
    }
  }
}

.bscTbl01 {
  &.contactForm {
    .leadCauText {
      text-align: center;
      margin-top: 4em;
      @include gothic;

      &::before {
        content: "※";
        color: $ctRed;
      }
    }

    border-top: 1px solid $ctGray;

    table {
      border: none;
      max-width: 960px;
      margin: 2em auto 0;

      tbody {
        tr {
          border-bottom: 1px solid $ctGray;

          &:first-child {
            th {
              width: 30%;
            }
          }
        }

        th,
        td {
          font-size: 1.1em;
          line-height: 1.4;
        }

        th {
          background: none;
          border: none;
          position: relative;
          padding: 3em 1em 3em 3em;
          line-height: 1.2;
          vertical-align: top;

          &::before {
            content: "";
            display: inline-block;
            width: 1.5em;
            height: 2px;
            background-color: $c02;
            position: absolute;
            left: 1em;
            top: 3.5em;
          }

          @include pc {
            padding-bottom: 0;
          }
        }

        td {
          background: none;
          border: none;
          padding: 3em 1em;
          @include gothic;
          vertical-align: top;

          input[type="text"],
          textarea {
            appearance: none;
            background-color: #efefef;
            border: 2px solid transparent;
            transition: all 0.3s $easeInOutSine;

            &:focus {
              outline: none;
              border: 2px solid rgba($c02, 0.5) !important;
              background-color: inherit !important;
              border-radius: 3px;
            }
          }

          &.nc {
            input,
            textarea {
              &.empty {
                border: 2px solid $errBdr;
                background-color: $errBg;
                border-radius: 3px;
              }
            }

            select {
              &.empty {
                border: 2px solid $errBdr;
                background-color: $errBg;
                border-radius: 3px;
              }
            }
          }

          input[type="text"] {
            padding: 0.7em 1em 0.5em;
          }

          @include pc {
            padding: 2em 1em;
          }
        }
      }
    }
  }
}

.subscribeBtn {
  text-align: center;
  max-width: 700px;
  margin: 3em auto 0;
  text-align: center;

  p {
    background-color: $c01;
    border: none;
    color: #fff;
    display: inline-block;
    width: 49%;

    + p {
      margin-left: 1.3%;
    }

    @include tab {
      float: none;
      width: 100%;

      + p {
        margin-left: 0;
        margin-top: 1em;
      }
    }

    @include transition;
    position: relative;

    i {
      display: inline-block;
      font-size: 0.6em;
      @include posCenterY;
      right: 15%;
      color: #fff;
    }

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.1;
      pointer-events: none;
    }

    &.return {
      background-color: #dedede;
      color: $black;

      input {
        color: $black;
      }

      i {
        color: $black;
        left: 15%;
        right: auto;
      }
    }

    input {
      display: block;
      width: 100%;
      background: none;
      border: none;
      color: #fff;
      padding: 1.3em 0 1.2em;
      line-height: 140%;
      @include transition;
      text-decoration: none;
    }
  }
}

input {
  &.textFocus {
    color: #333;
  }

  &.wSS,
  &.wS,
  &.wM,
  &.wL,
  &.wLL,
  &.wFull {
    // background-color: #FCFBF8;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 1.3rem;
    line-height: 140%;
    padding: 8px 10px;

    &:focus {
      background-color: #efefef;
    }
  }

  vertical-align: middle;
}

.wSS {
  width: 85px;
}

.wS {
  width: 170px;
}

.wM {
  width: 270px;
}

.wL {
  width: 470px;
}

.wLL {
  width: 80%;
}

.wFull {
  width: 100%;
}

.imeOn {
  ime-mode: active;
}

.imeOff {
  ime-mode: disabled;
}

textarea.txtarea {
  background-color: #fcfbf8;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  height: 200px;
  padding: 10px;
  width: 100%;
}

option {
  font-size: 1.4rem !important;
}

label {
  cursor: pointer;

  &:hover {
    color: #999;
  }
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    margin-right: 3px;
  }
}

.formErr {
  color: #e54e52;
  font-weight: bold;
}

select.slctArea {
  background-color: #fcfbf8;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  padding: 8px;

  &[multiple="multiple"] {
    option {
      background-color: transparent !important;
    }
  }
}

input.inputErr,
textarea.inputErr {
  background-color: #fadfde;
}

input:focus,
select:focus,
textarea:focus {
  background-color: #eee;
}

.req {
  display: inline-block;
  background-color: #e60012;
  color: #fff;
  @include gothic;
  font-size: 1.1rem;
  padding: 0.4em 0.8em;
  margin-left: 3px;
  margin-right: 5px;
  vertical-align: middle;
}

.agreeBtn {
  margin-top: 1em;
}

.errText {
  box-sizing: border-box;
  color: $ctRed;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 1em;

  + .errText {
    margin-top: 0;
  }
}

.cauText {
  color: #333;
  font-size: 0.85em;
  line-height: 1.4 !important;
  margin-top: 1em;
}

.inputClm2 {
  li {
    display: inline-block;
    float: none;
    width: auto;
    margin-bottom: 0;

    &:nth-child(2) {
      float: none;
      margin-left: 1em;
    }

    span,
    input {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      margin-right: 1em;
    }
  }

  @include pc {
    li {
      display: block;
      width: 100%;

      span {
        display: block;
        margin-bottom: 1em;
        width: auto;
        margin-right: 0;
      }

      input {
        width: 100%;
      }

      &:nth-child(2) {
        margin-top: 1em;
        margin-left: 0;
      }
    }
  }
}

.cBoxList {
  li {
    display: inline-block;

    + li {
      margin-left: 1.5em;
    }

    @include tab {
      display: block;
      margin-top: 1em;

      + li {
        margin-left: 0;
      }
    }

    label {
      position: relative;
      margin: 0.5em;
      cursor: pointer;

      input {
        position: relative;
        margin: 0 1rem 0 0;
        cursor: pointer;

        &::before {
          position: absolute;
          z-index: 1;
          top: 0.125rem;
          left: 0.1875rem;
          width: 0.75rem;
          height: 0.375rem;
          content: "";
          transition: transform 0.2s cubic-bezier(0.45, 1.8, 0.5, 0.75);
          transform: rotate(-45deg) scale(0, 0);
          border: 2px solid $c01;
          border-top-style: none;
          border-right-style: none;
        }

        &:checked::before {
          -webkit-transform: rotate(-45deg) scale(1, 1);
          transform: rotate(-45deg) scale(1, 1);
        }

        &::after {
          position: absolute;
          top: -0.125rem;
          left: 0;
          width: 1rem;
          height: 1rem;
          content: "";
          cursor: pointer;
          border: 2px solid #cccccc;
          background: #ffffff;
        }
      }
    }
  }

  + .cBoxList {
    margin-top: 1.5em;

    @include tab {
      margin-top: 1em;
    }
  }
}

.formAddressArea {
  p:not(.first) {
    margin-top: 1em;
  }

  .selectBox {
    margin-top: 1em;
  }
}

.selectBox {
  overflow: hidden;
  max-width: 470px;
  width: 100%;
  text-align: center;
  position: relative;
  // border: 2px solid transparent;
  background: #efefef;

  select {
    width: 100%;
    padding: 0.7em 1em 0.5em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid transparent;
    @include transition;

    &::-ms-expand {
      display: none;
    }
  }

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid $c02;
    pointer-events: none;
    @include posCenterY;
    right: 15px;
  }
}

.privacyCheckBox {
  max-width: 1000px;
  border: 4px solid $c02;
  padding: 3em;
  margin: 5em auto 0;
  @include gothic;
  text-align: center;

  .inr {
    max-width: 800px;
    margin: 0 auto;
  }

  h3 {
    font-size: 1.2em;
    padding-bottom: 1em;
    border-bottom: 1px solid $c02;
  }

  p {
    margin-top: 2em;
  }

  p.caution {
    color: $ctRed;
  }

  .bscBtn01 {
    max-width: 320px;
    margin: 2em auto 0;

    a {
      background-color: $c01;
      @include notoserif;
      font-size: 1.1em;

      i {
        position: static;
        font-size: 0.6em;
        vertical-align: middle;
        margin-right: 0.5em;
      }

      &:hover {
        background-color: rgba($c01, 0.8);
      }
    }
  }

  .agreeBtn {
    padding: 1em;
    border: 2px solid $errBdr;
    background-color: $errBg;

    label {
      position: relative;
      margin: 0.5em;
      cursor: pointer;

      input {
        position: relative;
        margin: 0 1rem 0 0;
        cursor: pointer;

        &::before {
          position: absolute;
          z-index: 1;
          top: 0.125rem;
          left: 0.1875rem;
          width: 0.75rem;
          height: 0.375rem;
          content: "";
          transition: transform 0.2s cubic-bezier(0.45, 1.8, 0.5, 0.75);
          transform: rotate(-45deg) scale(0, 0);
          border: 2px solid $c01;
          border-top-style: none;
          border-right-style: none;
        }

        &:checked::before {
          -webkit-transform: rotate(-45deg) scale(1, 1);
          transform: rotate(-45deg) scale(1, 1);
        }

        &::after {
          position: absolute;
          top: -0.125rem;
          left: 0;
          width: 1rem;
          height: 1rem;
          content: "";
          cursor: pointer;
          border: 2px solid #cccccc;
          background: #ffffff;
        }
      }
    }
  }
}

@include pc {
  #breadCrumbs {
    display: none;
  }

  /*contents*/
  #contents {
    margin: 0 auto;
    // padding-top: 40px;
    width: 100%;
  }

  #main {
    float: none;
    width: 100%;

    .topicsList {
      article {
        width: 48.5%;
        margin: 0;

        &:nth-child(2n) {
          float: right;
        }

        + article + article {
          margin-top: 3em;
        }

        @include sp {
          width: 100%;
          float: none;

          &:nth-child(2n) {
            float: none;
          }

          + article {
            margin-top: 2em;
          }
        }
      }
    }
  }

  .bscBox01,
  .bscBox02 {
    padding: 5%;
  }

  .bscTbl01 {
    th,
    td {
      display: block;
      // font-size: 1.1rem;
      padding: 10px 3%;
      width: 100%;
    }

    &.scroll {
      th,
      td {
        display: table-cell;
      }
    }
  }

  .clm4 li {
    float: left;
    margin-right: 0;
    width: 48.5%;

    &.even,
    &:nth-child(2n) {
      float: right;
    }

    + li + li {
      margin-top: 2em;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  .clm3 li {
    float: left;
    margin-right: 0;
    width: 48.5%;

    &.even,
    &:nth-child(2n) {
      float: right;
    }
  }

  .subscribeBtn input {
    padding: 15px 0 13px;
    margin-top: 5px;
    width: 100%;
  }

  input {
    &.wSS,
    &.wS,
    &.wM,
    &.wL,
    &.wLL {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .wSS {
    width: 30%;
  }

  .wS {
    width: 50%;
  }

  .wM,
  .wL,
  .wLL {
    width: 100%;
  }

  .imeOn {
    ime-mode: active;
  }

  .imeOff {
    ime-mode: disabled;
  }

  textarea.txtarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 200px;
    width: 100%;
  }

  select.timearea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
  }

  option {
    font-size: 1.4rem !important;
  }

  label {
    cursor: pointer;

    &:hover {
      color: #999;
    }
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      margin-right: 3px;
    }
  }

  .formErr {
    color: #e54e52;
    font-weight: bold;
  }

  input.inputErr,
  textarea.inputErr {
    background-color: #fadfde;
  }

  .req {
    // color: #FFF;
    font-size: 10px;
    line-height: 100%;
    margin-left: 3px;
    padding: 2px 5px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .cauText {
    display: block;
  }

  //END
}
