#sitemap,
#term-of-use,
#privacy-policy {
  .secTitleBox {
    display: block;
    margin-bottom: 30px;
  }
  .mainBox {
    background-color: #fff;
    opacity: 0.9;
    padding: 50px 50px 60px;
    margin-bottom: 40px;
    @include pc {
      padding: 40px 20px;
    }
    @include sp {
      padding: 10px 3%;
    }
  }
  .subBox {
    margin-bottom: 100px;
    .titleBox {
      margin-bottom: 50px;
    }
  }
}

#term-of-use,
#privacy-policy {
  .box {
    margin-bottom: 50px;
    a {
      text-decoration: underline;
    }
    .txt01 {
      font-size: 14px;
      line-height: 2;
      &.mb20 {
        margin-bottom: 20px;
      }
      @include sp {
        font-size: 13px;
      }
    }
    .txt02 {
      font-size: 13px;
      color: #111;
      line-height: 1.5;
      @include sp {
        font-size: 12px;
      }
    }
  }
  .colorBox {
    background-color: $c03;
    width: 100%;
    max-width: 680px;
    margin: 15px auto;
    padding: 30px;
    @include sp {
      padding: 10px;
    }

    .title {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      border-bottom: 1px solid #000;
      padding-bottom: 8px;
      margin-bottom: 10px;
    }
  }
  .list {
    .txt {
      margin-bottom: 10px;
    }
    ul {
      padding-left: 1em;
    }
    li {
      line-height: 2.2;
      padding-left: 1em;
      text-indent: -1em;
    }
  }
}

#sitemap {
  .sMapList {
    h2 {
      a {
        display: block;
        color: #fff;
        padding: 1.2em 1em;
        background-color: $c01;
        text-decoration: none;
        font-size: 16px;
        margin-top: 2em;
        &:hover {
          opacity: 0.8;
        }
        i {
          font-size: 0.8em;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.5em;
        }
      }
      &.linkNone {
        display: block;
        color: #fff;
        padding: 1.2em 1em;
        background-color: $c01;
        text-decoration: none;
        margin-top: 2em;
      }
    }

    ul {
      overflow: hidden;
      margin-top: 1em;
      margin-bottom: 0;

      + h3 {
        margin-top: 2em;
      }

      li {
        float: left;
        width: 48%;
        border-bottom: 1px solid #ccc;

        &:nth-child(2n) {
          float: right;
        }

        + li + li {
          margin-top: 1.5em;
        }

        @include tab {
          float: none;
          width: 100%;

          &:nth-child(2n) {
            float: none;
          }

          + li {
            margin-top: 1em;
          }
        }
      }

      li {
        a {
          display: block;
          padding: 0.7em 0 0.7em 1em;
          position: relative;
          text-decoration: none;
          position: relative;
          line-height: 1.3;
          font-size: 16px;
          @include gothic;

          &::before {
            content: "-";
            display: inline-block;
            margin-right: 0.5em;
            font-weight: 600;
            position: absolute;
            @include posCenterY;
            @include transition;
            left: 0;
          }
          &:hover {
            transform: translateX(0.5em);
          }
        }
      }
    }
  }
}
