.head01Inr {
  &.tL {
    text-align: left;
    .head01 {
      &::before {
        left: 0;
      }
      &::after {
        right: -10%;
      }
    }
  }
  &.tR {
    text-align: right;
    .head01 {
      &::before {
        right: 0;
      }
      &::after {
        left: -10%;
      }
    }
  }
  .head01 {
    display: inline-block;
    font-size: 76px;
    color: #000;
    @include baseEn;
    letter-spacing: -3px;
    position: relative;
    line-height: 1;
    @include pc {
      font-size: 62px;
    }
    @include tab {
      font-size: 52px;
    }
    @include sp {
      font-size: 42px;
    }
    &::before {
      content: attr(data-text);
      position: absolute;
      top: -20px;
      display: block;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 3px;
      margin-bottom: 10px;
      line-height: 1.5;
    }
    &::after {
      content: "";
      display: inline-block;
      @include gradationBg02;
      opacity: 0.8;
      width: 100%;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 3px;
      margin-bottom: 10px;
      position: absolute;
      bottom: 0;
      @include pc {
        height: 30px;
      }
      @include tab {
        height: 20px;
      }
      @include sp {
        height: 10px;
      }
    }
    &.spline {
      @include tab {
        &::before {
          top: -50px;
        }
      }
    }
  }
}

.head02 {
  text-align: center;
  color: #000;
  font-size: 34px;
  letter-spacing: 3px;
  line-height: 1.3;
  @include tab {
    font-size: 30px;
  }
  @include sp {
    font-size: 28px;
  }
  &::after {
    content: attr(data-text);
    display: block;
    font-size: 15px;
    @include uniq02;
    color: $c02;
  }
}

.head03 {
  position: relative;
  font-size: 28px;
  margin-bottom: 40px;
  color: #000;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -20px;
    left: 0;
  }
  &::before {
    width: 100%;
    background-color: $c01;
  }
  &::after {
    width: 20%;
    background-color: $c02;
  }
  @include tab {
    font-size: 22px;
  }

  @include sp {
    font-size: 20px;
    margin-bottom: 50px;
  }
}

.head04 {
  margin-bottom: 10px;
  border-bottom: 1px solid #999;
  position: relative;
  padding-left: 30px;
  padding-bottom: 5px;
  font-size: 20px;
  @include sp {
    font-size: 16px;
    padding-left: 15px;
  }
  &::after,
  &::before {
    content: "";
    background-color: #d6d5c6;
    display: block;
    width: 2px;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &::before {
    background-color: #3d3d3d;
    height: 10px;
    z-index: 2;
  }
}

.head05 {
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
  padding-bottom: 5px;
  font-size: 20px;
  @include sp {
    font-size: 16px;
    padding-left: 15px;
  }
  &::after,
  &::before {
    content: "";
    background-color: #d6d5c6;
    display: block;
    width: 2px;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &::before {
    background-color: #3d3d3d;
    height: 10px;
    z-index: 2;
  }
}
