#sustainability {
  padding: 120px 0 0px;
  .sustainabilityBox {
    background-image: url(../cmn_img/sustainability/bkg.png);
    background-size: cover;
    background-attachment: fixed;
    position: relative;

    width: 100%;
    padding: 80px 0;
    color: #fff;
    text-align: center;
    @include tab {
      background-attachment: scroll;
      background-position: center center;
      padding: 0 10px;
    }

    .bkImg {
      @include posCenter;
      position: fixed;
      top: 50%;
      img {
        max-width: 100%;
        @include tab {
          width: 320px;
        }
      }
    }

    .titleBox {
      position: relative;
      .titleEn {
        font-size: 40px;
        font-style: italic;
        @include baseEn;
        margin-bottom: 20px;
        @include tab {
          font-size: 28px;
        }
      }
      .titleJa {
        font-size: 73px;
        line-height: 1;
        margin-bottom: 30px;
        @include tab {
          font-size: 58px;
        }
        @include sp {
          font-size: 38px;
        }
      }
    }
    .txt {
      font-size: 22px;
      line-height: 2;
      @include tab {
        font-size: 16px;
      }
      @include sp {
        font-size: 14px;
      }
      &.mb30 {
        margin-bottom: 30px;
      }
      &.mb50 {
        margin-bottom: 50px;
      }
      &.f50 {
        font-size: 50px;
        line-height: 1.2;
        font-weight: 600;
        @include tab {
          font-size: 32px;
        }
        @include sp {
          font-size: 24px;
        }
      }
    }
  }

  .bkgColorBox {
    background-color: #fff;
    padding: 80px 0;
  }

  .linkBox {
    width: 50%;
    margin: 0 auto;
    @include tab {
      width: 100%;
    }
    li {
      float: left;
      width: calc(96% / 2);
      margin-right: 2%;
      @include tab {
        float: none;
        width: 100%;
        margin-right: 0%;
        margin-bottom: 10px;
      }
      @include mintab {
        a {
          &:hover {
            .imgBox {
              img {
                transform: scale(1.1);
              }
              .txtBox {
                letter-spacing: 3px;
              }
            }
          }
        }
      }
      .imgBox {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        line-height: 0;
        img {
          width: 100%;
          max-width: 100%;
          @include transition;
        }
        .txtBox {
          @include posCenter;
          @include transition;
          text-align: center;
          color: #fff;
          line-height: normal;
          width: 100%;
          .txtEn {
            @include uniq01;
            font-size: 14px;
          }
          .txtJa {
            font-size: 20px;
          }
        }
      }
      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }

  .ceoBox {
    background-color: #ecf2f2;
    position: relative;
    overflow: hidden;
    width: 100%;
    line-height: 0;
    @include pc {
      padding: 60px 0 0;
    }
    .bkgImg {
      height: 100%;
      background-image: url(../cmn_img/sustainability/bkg02.png);
      background-size: cover;
      background-position: center;
      position: absolute;
      opacity: 0.8;
      width: 100%;
    }
    .secTitleBox {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      line-height: normal;
      padding: 40px 0;
      .head01Inr {
        margin-bottom: 20px;
      }
      .txt {
        font-size: 15px;
        &.tR {
          text-align: right;
          font-size: 16px;
          margin-top: 10px;
        }
      }
      @include pc {
        display: block;
        width: 100%;
      }
      @include sp {
        .head01Inr {
          .head01 {
            font-size: 44px;
          }
        }
      }
    }

    .imgBox {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      @include pc {
        display: block;
        width: 100%;
      }
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
#sdgs {
  .secTitleBox {
    display: block;
    margin-bottom: 30px;
  }
  .mainBox {
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0.9;
    padding: 50px 50px 60px;
    margin-bottom: 40px;
    @include pc {
      padding: 40px 20px;
    }
    @include sp {
      padding: 10px 3%;
    }
  }
  .subBox {
    .titleBox {
      margin-bottom: 50px;
    }

    .contentsBox {
      background-color: #fff;
      border: 1px solid #d1dde2;
      border-radius: 5px;
      padding: 40px 30px;
      margin-bottom: 50px;
      @include tab {
        padding: 20px 10px;
        margin-bottom: 50px;
      }
      &.box01 {
        text-align: center;
      }
      &.last {
        margin-bottom: 0;
      }

      .title01 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 20px;
        @include tab {
          font-size: 20px;
        }
      }
      .title02 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 10px;
        padding-left: 30px;
        text-indent: -30px;
        @include tab {
          font-size: 18px;
        }
        span {
          margin-right: 10px;
          vertical-align: middle;
          display: inline-block;
          width: 20px;
          height: 3px;
          background-color: $c02;
        }
      }
      .txt {
        line-height: 1.8;
        font-size: 16px;
        @include tab {
          font-size: 13px;
        }
        &.mb50 {
          margin-bottom: 50px;
        }
      }
      .imgBox {
        &.half {
          text-align: center;
          img {
            max-width: 50%;
            text-align: center;
            margin: 0 auto;
          }
        }
        img {
          max-width: 100%;
          width: 100%;
        }
      }
      .txtBox {
        .txt {
          font-size: 16px;
          @include tab {
            font-size: 13px;
          }
          &.indent {
            padding-left: 1em;
          }
        }
        .list {
          li {
            font-size: 16px;
            text-indent: -1em;
            padding-left: 1em;
            @include tab {
              font-size: 13px;
            }
          }
        }
      }
      .bscBtn01 {
        margin-top: 20px;
        &.mb30 {
          margin-bottom: 30px;
        }
        a {
          padding: 10px 80px;
        }
      }
      .markList {
        text-align: center;
        margin-bottom: 30px;
        @include tab {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          padding: 0 5px;
          img {
            max-width: 80px;
            width: 100%;
          }
        }
      }
      .floatBox {
        &.border {
          border-bottom: 1px solid #d1dde2;
          padding-bottom: 30px;
          margin-bottom: 30px;
        }
        .fL {
          float: left;
          width: 70%;
          @include tab {
            float: none;
            width: 100%;
            margin-bottom: 20px;
          }
        }
        .fR {
          float: right;
          width: 25%;
          @include tab {
            float: none;
            width: 100%;
          }
        }
      }
    }
  }
}
#history {
  p {
    font-size: 16px;
  }
}

/*--------------------pc 1024px---------------------*/

@include pc {
}

/*--------------------tab 768px---------------------*/

@include tab {
}

/*--------------------sp 480px---------------------*/

@include sp {
}
