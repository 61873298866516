body.scroll {
  #header {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
    height: 60px;
    #gNav {
      li {
        a {
          color: #000;
        }
      }
    }
  }
}
#header {
  position: fixed;
  z-index: 600;
  top: 0;
  width: 100%;
  height: 110px;
  overflow: hidden;
  @include transition;
  .logo {
    @include posCenterY;
    width: 230px;
    left: 0px;
    margin: 0 20px;
    a {
      img {
        max-width: 100%;
        @include transition;
      }
      &:hover {
        img {
          opacity: 0.8;
        }
      }
    }
  }

  #gNav {
    @include posCenterY;
    right: 30px;
    ul {
      li {
        display: inline-block;
        a {
          display: block;
          font-size: 16px;
          padding: 8px 20px;
          text-decoration: none;
          color: #fff;
          font-weight: 600;
          @include mintab {
            &:hover {
              color: $c01;
            }
          }
        }
      }
    }
  }
}

#spHeader,
.spMenu {
  display: none;
}

#spNav {
  display: none;
}

@include pc {
  #header,
  #gNav,
  #sideBar {
    display: none;
  }

  #spHeader {
    background-color: rgba(89, 165, 222, 0.8);
    height: 60px;
    padding: 7px 0;
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 980;
    @include transition;

    .logo {
      @include posCenterY;
      left: 10px;
      a {
        display: block;
        max-width: 230px;
        img {
          width: 100%;
        }
      }
    }

    ul {
      overflow: hidden;
      zoom: 1;
      position: absolute;
      right: 55px;
      top: 16px;
    }

    li {
      float: left;
      margin: 0 5px;
      text-align: center;

      a {
        color: $white;
        // font-size: 2.6rem;
        line-height: 1;

        i {
        }
      }
    }
    .spMenu {
      // background: red;
      cursor: pointer;
      display: block;
      height: 60px;
      width: 60px;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1010;

      span {
        background-color: $white;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        /* Fix for IE7 */
        *display: inline;
        /* Fix for IE7 */
        height: 2px;
        width: 34px;
        position: absolute;
        right: 13px;
        @include transition;
      }

      span {
        &:nth-of-type(1) {
          top: 18px;
        }

        &:nth-of-type(2) {
          top: 28px;
        }

        &:nth-of-type(3) {
          top: 38px;
        }
      }

      &.active {
        height: 40px;
        width: 40px;

        span {
          background-color: #000;

          &:nth-of-type(1) {
            -webkit-transform: translateY(10px) rotate(-45deg);
            transform: translateY(10px) rotate(-45deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            -webkit-transform: translateY(-10px) rotate(45deg);
            transform: translateY(-10px) rotate(45deg);
          }
        }

        small {
          color: #000;
        }
      }
    }
    /*spNav*/
    #spNav {
      height: 100%;
      display: block;
      @include transition;
      visibility: hidden;
      padding-top: 59px;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 990;
      transform: translateY(5%);
      background-color: $white;
      opacity: 0;

      &.active {
        visibility: visible;
        opacity: 1;
        top: 0;
        transform: translateY(0);
      }

      #spBg {
        // background: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 500%;
        position: fixed;
        right: -50%;
        top: 0;
        z-index: -1;
        opacity: 0;
        @include transition;
        pointer-events: none;
      }

      &.active #spBg {
        right: 0;
        opacity: 1;
        pointer-events: auto;
      }

      &::after {
        // background: rgba(255, 255, 255, 0.8);
        background-color: #f9f9f9;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }

      &::before,
      &::after {
        opacity: 0;
        @include transition;
      }

      &.active {
        &::before,
        &::after {
          opacity: 1;
        }
      }

      .closeBtn {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 15px 3%;
        text-align: right;
      }

      nav {
        border-top: 1px solid #eee;
        @include transition;
        height: calc(100vh - (59px));
        overflow-y: scroll;

        ul {
          position: static;

          li {
            border-bottom: 1px solid #eee;
            float: none;
            width: 100%;
            float: none;
            margin: 0;
            &.logoImg {
              a {
                padding: 5px 0px;
                img {
                  max-width: 160px;
                }
              }
            }

            button {
              background-color: transparent;
              border: none;
              cursor: pointer;
              outline: none;
              padding: 0;
              appearance: none;
              width: 100%;
            }

            a,
            span,
            button {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              color: #666;

              @include sp {
                @include fontSizeVw(13);
              }

              font-weight: 300;
              text-align: center;
              display: block;
              padding: 20px 10px;
              position: relative;
            }

            a i,
            span i,
            button i {
              font-size: 1.3em;
              color: $c01;
              text-align: right;
              margin: auto;
              position: absolute;
              right: 5%;
              top: 50%;
              margin-top: -0.5em;
              z-index: 2;
            }

            a:hover {
              background-color: rgba(0, 73, 114, 0.63);
              color: $white;
              text-decoration: none;

              &::before {
                color: $white;
              }
            }

            .subNav {
              display: none;

              li {
                border-bottom: none;

                a {
                  font-size: 0.8em;
                  padding: 14px 2em 12px 1.5em;
                }
              }
            }

            &.sub {
              span {
                position: relative;

                &::before,
                &::after {
                  content: "";
                  display: block;
                  width: 9px;
                  border-bottom: 1px solid $c01; // background-color: $c01;
                  position: absolute;
                  right: 6.3%;
                  @include posCenterY;
                  @include transition;
                }

                &::after {
                  -webkit-transform: rotate(90deg);
                  -moz-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  -o-transform: rotate(90deg);
                  transform: rotate(90deg);
                }

                &.active {
                  &::after {
                    -webkit-transform: rotate(0);
                    -moz-transform: rotate(0);
                    -ms-transform: rotate(0);
                    -o-transform: rotate(0);
                    transform: rotate(0);
                  }
                }
              }
            }
          }
        }
      }

      // ul.subNav li a::before {
      //     content: "-";
      //     display: inline-block;
      //     font-size: 1.3em;
      //     color: $c01;
      //     @include posCenterY;
      //     left: 35%;
      // }
    }
  }
}
