#initiatives {
  .secTitleBox {
    display: block;
    margin-bottom: 30px;
    .subTitle {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      padding-left: 50px;
      @include pc {
        padding-left: 0px;
        display: block;
        text-align: left;
      }
      @include tab {
        font-size: 15px;
      }
    }
    .head01Inr {
      .head01 {
        vertical-align: middle;
        &::before {
          @include tab {
            top: -40px;
          }
          @include sp {
            font-size: 14px;
            top: -40px;
          }
        }
      }
    }
  }

  .initiativesTitle {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px 10px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 10px -6px #7d7d7d;
    @include pc {
      padding-bottom: 0px;
    }
    li {
      float: left;
      text-align: center;
      width: 200px;
      margin: 0 5px;
      a {
        background-color: #d5e1e6;
        padding: 8px 0px;
        border-radius: 25px;
        @include transition;
        font-size: 15px;
        display: block;
      }
      &.active {
        a {
          @include gradationBg01;
          color: #fff;
        }
      }
      &:hover {
        a {
          opacity: 0.8;
        }
      }
      @media (max-width: 1150px) {
        font-size: 14px;
        width: 180px;
      }
      @include pc {
        width: calc((100% - 20px) / 2);
        margin-bottom: 20px;
      }
      @include sp {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .initiativesList {
    margin-bottom: 50px;
    li {
      float: left;
      width: calc((100% - 40px) / 3);
      margin-right: 20px;
      background-color: #fff;
      padding: 20px;
      margin-bottom: 20px;
      &:nth-child(3n) {
        margin-right: 0%;
      }
      @include pc {
        width: calc((100% - 20px) / 2);
        &:nth-child(3n) {
          margin-right: 20px;
        }
        &:nth-child(2n) {
          margin-right: 0%;
        }
      }
      @include tab {
        width: 100%;
        float: none;
        margin-right: 0px;
        &:nth-child(3n) {
          margin-right: 0px;
        }
        &:nth-child(2n) {
          margin-right: 0%;
        }
      }
      .imgBox {
        background-position: center center;
        background-size: cover;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }

      .titleBox {
        margin-bottom: 10px;
        .cat {
          padding: 3px 5px;
          color: #fff;
          font-size: 15px;
          &.cat01 {
            background-color: $catClr01;
          }
          &.cat02 {
            background-color: $catClr03;
          }
          &.cat03 {
            background-color: $catClr02;
          }
          &.cat04 {
            background-color: $catClr04;
          }
        }
        time {
          font-size: 14px;
          padding-left: 10px;
          color: #888888;
          @include uniq02;
        }
      }
      .txt {
        font-size: 16px;
        margin-bottom: 30px;
        font-weight: 600;
        height: 75px;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .sdgs {
        li {
          float: none;
          display: inline-block;
          vertical-align: bottom;
          padding: 0;
          padding-right: 10px;
          width: 70px;
          margin: 0;
          &.large {
            width: 80px;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .detail {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 10px -6px #7d7d7d;
    padding: 60px;
    @include tab {
      padding: 30px;
    }
    @include sp {
      padding: 30px 10px;
    }
    .detailTitleBox {
      margin-bottom: 30px;
      .cat {
        padding: 3px 8px;
        color: #fff;
        font-size: 20px;
        &.cat01 {
          background-color: $catClr01;
        }
        &.cat02 {
          background-color: $catClr02;
        }
        &.cat03 {
          background-color: $catClr03;
        }
        &.cat04 {
          background-color: $catClr04;
        }
      }
      time {
        font-size: 20px;
        display: block;
        @include baseEn;
        margin-bottom: 15px;
      }
      .detailTitle {
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 600;
      }
      .tagList {
        li {
          display: inline-block;
          background-color: #dedede;
          padding: 1px 12px;
          border-radius: 25px;
          font-size: 18px;
          margin-bottom: 10px;
          @include tab {
            font-size: 14px;
          }
          &.tagImg {
            background-color: transparent;
            img {
              width: 100%;
              max-width: 22px;
            }
          }
        }
      }
    }

    .sdgsBox {
      display: table;
      width: 100%;
      margin-bottom: 50px;
      @include sp {
        display: block;
      }
      .txtBox {
        @include tableCell;
        width: 20%;
        background-color: $c02;
        text-align: center;
        color: #fff;
        font-size: 20px;
        @include tab {
          width: 30%;
          font-size: 16px;
        }
        @include sp {
          display: block;
          width: 100%;
          padding: 10px 0;
        }
      }
      .sdgsImg {
        @include tableCell;
        width: 80%;
        padding: 30px;
        background-color: #dbe8ee;
        @include tab {
          width: 70%;
        }
        @include sp {
          display: block;
          width: 100%;
        }
        .sdgs {
          li {
            display: inline-block;
            vertical-align: bottom;
            padding: 0;
            padding-right: 10px;
            width: 100px;
            margin: 0;
            @include tab {
              width: 60px;
            }
            @include sp {
              width: 80px;
            }
            &.large {
              width: 120px;
              @include tab {
                width: 80px;
              }
              @include sp {
                width: 100px;
              }
            }
            img {
              max-width: 100%;
            }
          }
        }
      }
    }

    .profileBox,
    .linkBox {
      border: 1px solid #d1dde2;
      background-color: #fff;
      padding: 30px;
      margin-bottom: 50px;
      .title {
        background-color: $c02;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        padding: 5px 10px;
        margin-bottom: 20px;
      }

      li {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #9a9a9a;
        text-indent: 0em;
        padding-left: 0em;
        &::before {
          content: none;
        }

        .name {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .txt {
          font-size: 13px;
        }
        i {
          color: $c01;
          padding-right: 10px;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
      }
      &.linkBox {
        li {
          font-weight: 600;
        }
      }
    }

    .relationBox {
      .title {
        background-color: $c02;
        color: #fff;
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
        margin-bottom: 30px;
      }
    }
  }
}

/*--------------------pc 1024px---------------------*/

@include pc {
}

/*--------------------tab 768px---------------------*/

@include tab {
}

/*--------------------sp 480px---------------------*/

@include sp {
}
