@charset "utf-8";

body {
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; /* 背景カラー */
    z-index: 9999; /* 一番手前に */
    pointer-events: none; /* 他の要素にアクセス可能にするためにポインターイベントは無効に */
    opacity: 0; /* 初期値 : 透過状態 */
    -webkit-transition: opacity 0.8s ease; /* アニメーション時間は 0.8秒 */
    transition: opacity 0.8s ease;
    // visibility: hidden;
  }
  &.fadeOut {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.moveBox {
  .inr {
    display: block;
    position: relative;
    overflow: hidden;
    width: auto;
    font-style: normal;
    &:before {
      background-color: $c02;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      @include transition02;
    }
    &:after {
      background-color: $c01;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      @include transition02;
      transition-delay: 0.1s;
    }
  }
}

// .moveBox {
//     .inr {
//         &:before {
//             // @include transition02;
//             transition: 1s ease-in-out;
//         }
//         &:after {
//             // @include transition02;
//             transition: 1s ease-in-out;
//             transition-delay: 0.3s;
//         }
//     }
// }

.moveBox.vt {
  &.showTime {
    .inr {
      &:before,
      &:after {
        top: 100%;
      }
      &:before,
      &:after {
        top: 100%;
      }
    }
  }
}

.moveBox.hr {
  &.showTime {
    .inr {
      &:before,
      &:after {
        left: 100%;
      }
      &:before,
      &:after {
        left: 100%;
      }
    }
  }
}

.fadeIn {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s $easeOutQuad;
  &.on {
    opacity: 1;
    transform: translateY(0);
  }
  &.fadeOnly {
    transform: translateY(0);
  }
}

.fadeBlur {
  opacity: 0;
  filter: blur(100px);
  transition: all 0.8s $easeOutQuad;
  &.on {
    opacity: 1 !important;
    filter: blur(0) !important;
  }
}

.imgShadow {
  line-height: 0;
  img {
    display: inline-block;
    @include transition;
    transform: translate(7px, 7px);
    transition-delay: 0.8s;
    max-width: 100%;
  }
  &.on {
    img {
      transform: translate(0);
      box-shadow: 14px 14px 0 #f0f0f0;
    }
  }
  @include tab {
    img {
      transform: translate(3px, 3px);
    }
    &.on {
      img {
        box-shadow: 6px 6px 0 #f0f0f0;
      }
    }
  }
}
