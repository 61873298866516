body.scroll {
  #bkgColorBox {
    .copy {
      opacity: 0;
    }
  }
}

#bkgColorBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  .bkgSlider {
    margin: 0;
    overflow: hidden;
    position: relative;
    z-index: -5;
    div {
      height: 100vh;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .bkgColorBoxInr {
    width: 100vw;
    height: 100vh;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(30deg, #1bd2e0, #5fb3e5 30%, #83e3c7 60%, #cce592 100%);
    animation: Gradient 5s ease infinite;
  }
  .copy {
    @include posCenterY;
    @include transition;
    right: 50px;
    writing-mode: vertical-rl;
    z-index: 1;
    @media (max-width: 1300px) {
      right: 1%;
    }
    p {
      font-size: 11px;
      color: #fff;
      @include uniq01;
    }
  }
  @keyframes Gradient {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
}
