#company {
  .companyBox {
    text-align: center;
    background-image: url(../cmn_img/company/company_bkg.png);
    background-position: center;
    background-size: cover;
    margin-bottom: 60px;
    .companyImg {
      img {
        width: 180px;
        margin-right: 40px;
        margin-bottom: 20px;
        @include tab {
          width: 150px;
        }
        @include sp {
          margin-right: 30px;
          width: 100px;
        }
      }
    }
    .companyTitle {
      margin-bottom: 80px;
      @include tab {
        margin-bottom: 40px;
      }
      img {
        width: 300px;
        @include tab {
          width: 250px;
        }
        @include sp {
          width: 180px;
        }
      }
    }
    .companyTxt {
      font-size: 18px;
      display: inline-block;
      margin-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 0px 8px;
      @include tab {
        font-size: 15px;
      }
      &.mb50 {
        margin-bottom: 50px;
        @include tab {
          margin-bottom: 30px;
        }
      }
    }
  }

  .Box01 {
    .ceoBox {
      width: 80%;
      margin: 0 auto 60px;
      position: relative;
      @include tab {
        width: 100%;
      }
      .imgBox {
        width: 60%;
        float: right;
        @include tab {
          float: none;
          width: 100%;
          padding-top: 80px;
          margin-bottom: 20px;
        }
        img {
          max-width: 100%;
        }
        .nameBoxInr {
          width: 100%;
          position: relative;
          .nameBox {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            background-color: #000;
            color: #fff;
            padding: 10px 20px;
            border-bottom: 3px solid #009dda;
            .position {
              font-size: 16px;
              padding-right: 20px;
              @include tab {
                font-size: 14px;
                padding-right: 10px;
              }
            }
            .name {
              font-size: 28px;
              @include tab {
                font-size: 20px;
              }
            }
          }
        }
      }

      .titleBox {
        position: absolute;
        top: 20%;
        left: 0;
        display: block;
        line-height: 1.6;
        color: #000;
        text-align: left;
        @include tab {
          top: 0%;
        }
        @include sp {
          line-height: 2;
          padding: 20px 3%;
        }
        .title {
          @include uniq01;
          font-size: 60px;
          letter-spacing: -3px;
          margin-bottom: 20px;

          @include tab {
            font-size: 40px;
            line-height: 2;
            margin-bottom: 0px;
          }
          @include sp {
            font-size: 30px;
            letter-spacing: 0px;
          }
          &::before {
            content: attr(data-text);
            position: absolute;
            top: 0px;
            display: block;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 3px;
            margin-bottom: 10px;
            @include sp {
              font-size: 13px;
              margin-bottom: 0px;
            }
          }
        }
        .bscBtn01 {
          display: inline-block;
          @include pc {
            a {
              padding: 20px 80px;
            }
          }
        }
      }
    }
    .linkBox {
      width: 80%;
      margin: 0 auto;
      @include tab {
        width: 100%;
      }
      li {
        float: left;
        width: calc(96% / 2);
        margin-right: 2%;
        @include tab {
          float: none;
          width: 100%;
          margin-right: 0%;
          margin-bottom: 10px;
        }
        @include mintab {
          a {
            &:hover {
              .imgBox {
                img {
                  transform: scale(1.1);
                }
                .txtBox {
                  letter-spacing: 3px;
                }
              }
            }
          }
        }
        .imgBox {
          width: 100%;
          max-width: 100%;
          overflow: hidden;
          position: relative;
          line-height: 0;
          img {
            width: 100%;
            max-width: 100%;
            @include transition;
          }
          .txtBox {
            @include posCenter;
            @include transition;
            text-align: center;
            color: #fff;
            line-height: normal;
            width: 100%;
            .txtEn {
              @include uniq01;
              font-size: 14px;
            }
            .txtJa {
              font-size: 20px;
            }
          }
        }
        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }
  }

  .Box02 {
    .subBox {
      text-align: center;
      margin-bottom: 0px;
      .brandsTxt {
        font-size: 18px;
        margin-bottom: 30px;
      }
      .imgBox {
        img {
          max-width: 100%;
          width: 50%;
          @include pc {
            width: 80%;
          }
          @include tab {
            width: 100%;
          }
        }
      }
      @include pc {
        .scroll {
          overflow-x: auto;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
          &::after {
            content: "※フリックで横スクロールができます。";
            display: block;
            font-size: 1em;
            margin: 1em;
            margin-bottom: 2em;
          }
        }
        .scrollInr {
          display: inline-block;
          white-space: nowrap;
        }
      }
      .numberList {
        li {
          display: inline-block;
          vertical-align: top;
          color: $c02;
          margin-bottom: 20px;
          .title {
            font-weight: 600;
            display: inline-block;
            font-size: 14px;
            border-bottom: 2px solid $c02;
            margin-bottom: 20px;
          }
          .icon {
            margin-bottom: 30px;
            img {
              max-width: 50%;
            }
          }
          .txt {
            font-size: 48px;
            @include uniq03;
            letter-spacing: -3px;
            line-height: 1;
            span {
              font-size: 18px;
            }
          }
          &:nth-child(6) {
            .icon {
              margin-bottom: 15px;
            }
          }
          &:nth-child(7) {
            padding: 0 20px;
            .icon {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
#company,
#message,
#profile,
#history {
  .secTitleBox {
    display: block;
    margin-bottom: 30px;
  }

  .mainBox {
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0.9;
    padding: 50px 50px 60px;
    margin-bottom: 40px;
    @include pc {
      padding: 40px 20px;
    }
    @include sp {
      padding: 10px 3%;
    }
  }
  .subBox {
    margin-bottom: 100px;
    .titleBox {
      margin-bottom: 50px;
    }
  }
  .linkBox {
    li {
      float: left;
      width: calc(96% / 3);
      margin-right: 2%;
      @include tab {
        float: none;
        width: 100%;
        margin-right: 0%;
        margin-bottom: 10px;
      }
      @include mintab {
        a {
          &:hover {
            .imgBox {
              img {
                transform: scale(1.1);
              }
              .txtBox {
                letter-spacing: 3px;
              }
            }
          }
        }
      }
      .imgBox {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        line-height: 0;
        img {
          width: 100%;
          max-width: 100%;
          @include transition;
        }
        .txtBox {
          @include posCenter;
          @include transition;
          text-align: center;
          color: #fff;
          line-height: normal;
          width: 100%;
          .txtEn {
            @include uniq01;
            font-size: 14px;
          }
          .txtJa {
            font-size: 20px;
          }
        }
      }
      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }
}

#message {
  .messageImgBox {
    position: relative;
    width: 100%;
    line-height: 0;
    img {
      max-width: 100%;
    }
    .titleBox {
      line-height: 1.6;
      @include posCenterY;
      left: 10%;
      color: #000;
      @include sp {
        position: static;
        transform: translateY(0%);
        background-color: #fff;
        opacity: 0.9;
        line-height: 2;
        padding: 20px 3%;
      }
      .title {
        @include uniq01;
        font-size: 60px;
        letter-spacing: -3px;
        @include tab {
          font-size: 40px;
          line-height: 2;
        }
        @include sp {
          font-size: 30px;
          letter-spacing: 0px;
        }
        &::before {
          content: attr(data-text);
          position: absolute;
          top: 0px;
          display: block;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 3px;
          margin-bottom: 10px;
          @include sp {
            font-size: 13px;
            margin-bottom: 0px;
          }
        }
      }
      .subTitle {
        font-size: 26px;
        margin-bottom: 30px;
        @include tab {
          font-size: 20px;
        }
        @include sp {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      .name {
        font-size: 16px;
        font-weight: 600;
        @include sp {
          font-size: 13px;
        }
      }
    }
  }
  .messageTxtBox {
    background-color: #fff;
    opacity: 0.9;
    padding: 50px 50px 60px;
    margin-bottom: 40px;
    @include sp {
      padding: 10px 3%;
    }
    .txt {
      font-size: 18px;
      line-height: 2;
      margin-bottom: 20px;
      @include pc {
        font-size: 16px;
      }
      @include sp {
        font-size: 13px;
      }
    }
    .txtR {
      text-align: right;
      font-size: 16px;
    }
  }
}

#profile {
  .subBox {
    .imgBox {
      text-align: center;
      margin-bottom: 60px;
      img {
        max-width: 100%;
      }
    }
    .tableBox {
      border-top: 1px solid #d1dde2;
      &.fL {
        float: left;
        width: 49%;
        @include tab {
          float: none;
          width: 100%;
        }
      }
      &.fR {
        float: right;
        width: 49%;
        @include tab {
          float: none;
          width: 100%;
          border-top: none;
        }
      }
      table {
        width: 100%;
        tr {
          border-bottom: 1px solid #d1dde2;
        }
        th,
        td {
          vertical-align: top;
          padding: 20px 0;
          text-align: left;
          font-size: 16px;
          @include tab {
            display: block;
            width: 100%;
            padding: 10px 0;
            text-align: center;
          }
          .mapLink {
            padding-top: 10px;
            a {
              color: $c01;
              @include uniq02;
              font-weight: 600;
            }
          }
        }
        th {
          width: 150px;
          @include pc {
            width: 120px;
          }
          @include tab {
            width: 100%;
          }
        }
      }
    }
  }
}

#history {
  .historyBox {
    position: relative;
    padding-bottom: 30px;
    .line {
      display: block;
      position: absolute;
      left: 288px;
      height: 100%;
      width: 2px;
      background-color: #d1dde2;
      z-index: -1;
      margin: auto;
      transform-origin: top center;
      transition-delay: 1.5s;
      transition: 2s;
      transform: scaleY(0);
      &.on {
        transform: scaleY(1);
      }
      @media (max-width: 1160px) {
        left: 208px;
      }
      @include tab {
        left: 95px;
      }
      @include sp {
        display: none;
        left: 75px;
      }
    }
    .age {
      display: inline-block;
      vertical-align: top;
      line-height: 1;
      font-size: 34px;
      width: 200px;
      @include tab {
        font-size: 28px;
      }
      @media (max-width: 1160px) {
        width: 120px;
      }
      @include tab {
        width: 100%;
        margin-bottom: 20px;
      }
      span {
        background-color: $c02;
        color: #fff;
        padding: 3px;
      }
    }

    .year {
      display: inline-block;
      vertical-align: top;
      width: 200px;
      font-size: 20px;
      @media (max-width: 1160px) {
        width: 150px;
      }

      .yearTxt {
        display: inline-block;
        vertical-align: middle;
        padding-right: 30px;
      }
      .circle {
        width: 15px;
        height: 15px;
        border-radius: 50px;
        display: inline-block;
        background-color: $c02;
        vertical-align: middle;
        @include sp {
          display: none;
        }
      }
    }

    .txtBox {
      display: inline-block;
      width: calc(100% - 400px);
      font-size: 16px;
      padding-top: 5px;
      @media (max-width: 1160px) {
        width: calc(100% - 270px);
      }
      @include tab {
        width: calc(100% - 150px);
      }
      @include sp {
        width: 100%;
        font-size: 14px;
      }
      .imgBox {
        padding-top: 10px;

        &.half {
          img {
            max-width: 150px;
          }
          @include pc {
            max-width: 100%;
            img {
              max-width: 40%;
            }
          }
        }
        img {
          max-width: 150px;
          @include tab {
            max-width: 200px;
          }
        }
      }
    }
  }
}

/*--------------------pc 1024px---------------------*/

@include pc {
}

/*--------------------tab 768px---------------------*/

@include tab {
}

/*--------------------sp 480px---------------------*/

@include sp {
}
