@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:300,400,500,600,700,900&display=swap");

@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Heebo:300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700&display=swap");

// @import url("https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese");
// @import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,300i,400,400i,500,500i,600,600i,700,800,900&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900,900i&display=swap");

$pc: 1024px;
$tab: 768px;
$sp: 480px;
$defaultFontSize-px: 13px;
$defaultFontSize-rem: 1.3em;
$ie11: "_:-ms-lang(x)::-ms-backdrop";

@media (max-width: 1150px) {
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin ie11 {
  _:-ms-lang(x)::-ms-backdrop {
    @content;
  }
}

@mixin fontSizeVw($size) {
  $winW: 480;
  font-size: ($size / $winW) * 100 + vw;
}

@mixin minpc {
  @media (min-width: (1023px)) {
    @content;
  }
}

@mixin mintab {
  @media (min-width: (767px)) {
    @content;
  }
}

@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin fontSize {
  font-size: $defaultFontSize-rem;
  font-size: $defaultFontSize-px;
  @include sp {
    font-size: 12px;
  }
}

@mixin gradationFc {
  color: $c01;
  background: -webkit-linear-gradient(135deg, #0068b7, #00a4ba);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradationBg01 {
  background: rgb(0, 104, 183);
  background: linear-gradient(90deg, rgba(0, 104, 183, 1) 0%, rgba(58, 208, 221, 1) 100%);
}

@mixin gradationBg02 {
  background: rgb(0, 104, 183);
  background: linear-gradient(90deg, rgba(89, 165, 222, 1) 0%, rgba(58, 208, 221, 1) 100%);
}

@mixin transition {
  transition: all 0.3s $easeInOutQuad;
}

@mixin transition02 {
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

@mixin transition03 {
  transition: all 0.3s ease-in-out;
}

@mixin baseJa {
  font-family: YuGothic, "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, メイリオ, verdana,
    Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@mixin baseEn {
  font-family: "Josefin Sans", sans-serif;
}

@mixin hirakaku {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", Meiryo, メイリオ, verdana,
    Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@mixin gothic {
  font-family: YuGothic, "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, メイリオ, verdana,
    Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@mixin noto {
  font-family: "Noto Sans JP", sans-serif;
}

@mixin notoserif {
  font-family: "Noto Serif JP", serif;
}

@mixin uniq01 {
  font-family: "Roboto", sans-serif;
}
@mixin uniq02 {
  font-family: "Heebo", sans-serif;
}
@mixin uniq03 {
  font-family: "Oswald", sans-serif;
}
// @mixin uniq02 {
//   font-family: "Montserrat", sans-serif;
// }
// @mixin uniq04 {
//   font-family: "Lato", sans-serif;
// }
// @mixin uniq05 {
//   font-family: "Lora", serif;
// }
