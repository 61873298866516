//section
#contents {
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

section {
  clear: both;
  padding: 60px 0;
  margin-bottom: 0;

  &.last {
    margin-bottom: 0;
  }
}

.scInr {
  margin: 0 auto;
  max-width: 1240px;
  width: 97%;
}

.scInrS {
  margin: 0 auto;
  max-width: 1000px;
  width: 97%;
}

.scInrSS {
  margin: 0 auto;
  max-width: 880px;
  width: 97%;
}

.scInrW {
  margin: 0 auto;
  max-width: 1366px;
  width: 97%;
}

.scInrWW {
  margin: 0 auto;
  max-width: 1550px;
  width: 97%;
}

.is-pc {
  display: block !important;

  @include tab {
    display: none !important;
  }
}

.is-sp {
  display: none !important;

  @include tab {
    display: block !important;
  }
}

.spTel {
  pointer-events: none;
}

//width
.w5 {
  width: 5% !important;
}

.w10 {
  width: 10% !important;
}

.w15 {
  width: 15% !important;
}

.w18 {
  width: 18% !important;
}

.w20 {
  width: 20% !important;
}

.w25 {
  width: 25% !important;
}

.w30 {
  width: 30% !important;
}

.w35 {
  width: 35% !important;
}

.w40 {
  width: 40% !important;
}

.w45 {
  width: 45% !important;
}

.w50 {
  width: 50% !important;
}

.w55 {
  width: 55% !important;
}

.w60 {
  width: 60% !important;
}

.w65 {
  width: 65% !important;
}

.w70 {
  width: 70% !important;
}

.w75 {
  width: 75% !important;
}

.w80 {
  width: 80% !important;
}

.w85 {
  width: 85% !important;
}

.w90 {
  width: 90% !important;
}

.w95 {
  width: 95% !important;
}

.w100 {
  width: 100px;
}

.w200 {
  width: 200px;
}

.w300 {
  width: 300px;
}

.w400 {
  width: 400px;
}

.w450 {
  width: 450px;
}

.w500 {
  width: 500px;
}

.w600 {
  width: 600px;
}

.wFull {
  width: 100% !important;
}

/* indent */
.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.top0 {
  margin-top: 0 !important;
}

.top5 {
  margin-top: 5px !important;
}

.top10 {
  margin-top: 10px !important;
}

.top15 {
  margin-top: 15px !important;
}

.top20 {
  margin-top: 20px !important;
}

.top25 {
  margin-top: 25px !important;
}

.top30 {
  margin-top: 30px !important;
}

.top35 {
  margin-top: 35px !important;
}

.top40 {
  margin-top: 40px !important;
}

.btm0 {
  margin-bottom: 0 !important;
}

.btm5 {
  margin-bottom: 5px !important;
}

.btm10 {
  margin-bottom: 10px !important;
}

.btm15 {
  margin-bottom: 15px !important;
}

.btm20 {
  margin-bottom: 20px !important;
}

.btm25 {
  margin-bottom: 25px !important;
}

.btm30 {
  margin-bottom: 30px !important;
}

.btm35 {
  margin-bottom: 35px !important;
}

.btm40 {
  margin-bottom: 40px !important;
}

.btm45 {
  margin-bottom: 45px !important;
}

.btm50 {
  margin-bottom: 50px !important;
}

.btm55 {
  margin-bottom: 55px !important;
}

.btm60 {
  margin-bottom: 60px !important;
}

.btm65 {
  margin-bottom: 65px !important;
}

.btm70 {
  margin-bottom: 70px !important;
}

.btm75 {
  margin-bottom: 75px !important;
}

.btm80 {
  margin-bottom: 80px !important;
}

.btm85 {
  margin-bottom: 85px !important;
}

.btm90 {
  margin-bottom: 90px !important;
}

.pT0 {
  padding-top: 0 !important;
}

.pT5 {
  padding-top: 5px !important;
}

.pT10 {
  padding-top: 10px !important;
}

.pT15 {
  padding-top: 15px !important;
}

.pT20 {
  padding-top: 20px !important;
}

.pT25 {
  padding-top: 25px !important;
}

.pT30 {
  padding-top: 30px !important;
}

.pT35 {
  padding-top: 35px !important;
}

.pT40 {
  padding-top: 40px !important;
}

.pT45 {
  padding-top: 45px !important;
}

.pB0 {
  padding-bottom: 0 !important;
}

.pB5 {
  padding-bottom: 5px !important;
}

.pB10 {
  padding-bottom: 10px !important;
}

.pB15 {
  padding-bottom: 15px !important;
}

.pB20 {
  padding-bottom: 20px !important;
}

.lm5 {
  margin-left: 5px !important;
}

.lm10 {
  margin-left: 10px !important;
}

.lm15 {
  margin-left: 15px !important;
}

.lm20 {
  margin-left: 20px !important;
}

.lm50 {
  margin-left: 50px !important;
}

.lm60 {
  margin-left: 60px !important;
}

.lm70 {
  margin-left: 70px !important;
}

.rm5 {
  margin-right: 5px !important;
}

.rm10 {
  margin-right: 10px !important;
}

.rm15 {
  margin-right: 15px !important;
}

.rm20 {
  margin-right: 20px !important;
}

.vAT {
  vertical-align: top !important;
}

.vAM {
  vertical-align: middle !important;
}

.vAB {
  vertical-align: bottom !important;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

// size

.size10 {
  font-size: 1rem !important;
}

.size11 {
  font-size: 1.1rem !important;
}

.size12 {
  font-size: 1.2rem !important;
}

.size13 {
  font-size: 1.3rem !important;
}

.size14 {
  font-size: 1.4rem !important;
}

.size15 {
  font-size: 1.5rem !important;
}

.size16 {
  font-size: 1.6rem !important;
}

.size17 {
  font-size: 1.7rem !important;
}

.size18 {
  font-size: 1.8rem !important;
}

.size19 {
  font-size: 1.9rem !important;
}

.size20 {
  font-size: 2rem !important;
}

.size21 {
  font-size: 2.1rem !important;
}

.size22 {
  font-size: 2.2rem !important;
}

.size23 {
  font-size: 2.3rem !important;
}

.size24 {
  font-size: 2.4rem !important;
}

//float
.fltImgL {
  float: left;
  margin: 0 25px 20px 0;
}

.fltImgR {
  float: right;
  margin: 0 0 20px 25px;
}

.fltL {
  float: left;
}

.fltR {
  float: right;
}

.objectFitCover {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.cf {
  &:before {
    content: "";
    display: table;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  zoom: 1;
}

.cf_ {
  letter-spacing: -1em;
  li {
    letter-spacing: normal;
  }
  p {
    letter-spacing: normal;
  }
  div {
    letter-spacing: normal;
  }
}

br.clear,
.clear {
  display: inline !important;
  display: none;
  clear: both;
  line-height: 0px;
}

br.clear {
  clear: both;
  line-height: 0px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  br.clear {
    display: block !important;
    content: " ";
  }
}

@include pc {
  section {
    padding: 30px 0;
  }

  .scInr,
  .scInrS,
  .scInrW {
    float: none;
    margin: 0 3%;
    width: auto;
  }
  .fltL,
  .fltR {
    float: none;
  }

  .fltImgL,
  .fltImgR {
    float: none;
    margin: 0;
    text-align: center;
  }

  .fltImgL img,
  .fltImgR img {
    max-width: 100%;
    margin-bottom: 5px;
  }
}
