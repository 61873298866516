#footer {
  background-color: #fff;
  padding: 80px 0;
  width: 100%;
  @include tab {
    padding: 40px 0;
  }
  .ftInr {
    margin: 0 auto;
    max-width: 1200px;
    width: 97%;
    .fL {
      float: left;
      width: 50%;
      @include pc {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
      .flogo {
        margin-bottom: 10px;
        img {
          width: 230px;
          @include pc {
            width: 100%;
            max-width: 300px;
          }
        }
      }
      .linkList {
        @include sp {
          margin-bottom: 20px;
        }
        li {
          display: inline-block;
          padding-right: 10px;
          &:last-child {
            padding-right: 0px;
          }
          @include tab {
            padding-right: 20px;
          }
          a {
            font-size: 13px;
            i {
              font-size: 10px;
            }
            @include pc {
              font-size: 15px;
            }
            @include mintab {
              &:hover {
                text-decoration: underline;
                color: $c01;
              }
            }
          }
        }
      }
    }
    .fR {
      float: right;
      width: 50%;
      @include pc {
        float: none;
        width: 100%;
      }
      .linkList {
        text-align: right;
        @include pc {
          text-align: center;
        }
        @include sp {
          margin-bottom: 20px;
        }
        &.mb10 {
          margin-bottom: 10px;
          @include sp {
            margin-bottom: 20px;
          }
        }
        li {
          display: inline-block;
          padding-right: 10px;
          &:last-child {
            padding-right: 0px;
          }
          @include tab {
            padding-right: 20px;
          }
          a {
            font-size: 15px;

            @include mintab {
              &:hover {
                text-decoration: underline;
                color: $c01;
              }
            }
          }
        }
      }
    }
  }
}
