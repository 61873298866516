@charset "utf-8";

/* reset
-------------------*/

html {
  background-color: $white;
  font-size: 62.5%;
  /* sets the base font to 10px for easier math */
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
ol,
li,
dl,
dt,
dd,
p,
img {
  margin: 0;
  padding: 0;
}

body {
  @include baseJa;
  @include fontSize;
  color: $black;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  letter-spacing: 1px;
  padding-top: 0px;
  background-repeat: repeat;
  @media all and (-ms-high-contrast: none) {
    font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
      "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
  @include pc {
    padding-top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include baseJa;
    font-weight: 600;
  }
}

a {
  color: $gray03;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  @include transition;
  -webkit-backface-visibility: hidden; /* 追加 */
  backface-visibility: hidden; /* 追加 */
  &:link,
  &:hover,
  &:focus {
    color: $gray03;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    @include transition;
  }
  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style-type: none;
}

::selection {
  background-color: rgba($c01, 0.7);
  color: $white;
  /* Safari */
}

::-moz-selection {
  background-color: rgba($c01, 0.7);
  color: $white;
  /* Firefox */
}
