#recruit {
  section {
    padding: 60px 0 0;
  }
  .secTitleBox {
    display: block;
    margin-bottom: 30px;
  }
  .oneImgBox {
    background-image: url("../cmn_img/top/sec04bkg.jpg");
    background-position: center;
    background-size: cover;
    padding: 60px 0;
    text-align: center;
    .imgBox {
      text-align: center;
      img {
        width: 100%;
        max-width: 80%;
      }
    }
  }
  .linkBox {
    margin: 60px 0 0;
    li {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 30px 0;
      margin-bottom: 30px;
      a {
        display: block;
        margin: 0 auto;
        max-width: 1240px;
        width: 97%;
        &:hover {
          .imgBox {
            img {
              opacity: 0.8;
            }
          }
          .txtBox {
            .txt {
              color: $c01;
            }
          }
        }
        .imgBox {
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          @include tab {
            display: block;
            width: 100%;
            margin-bottom: 20px;
          }
          img {
            max-width: 100%;
            width: 100%;
            @include transition;
          }
        }
        .txtBox {
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          padding-left: 30px;
          @include tab {
            display: block;
            width: 100%;
          }
          .txt {
            font-size: 26px;
            @include transition;
            @include tab {
              font-size: 20px;
            }
            @include sp {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

/*--------------------pc 1024px---------------------*/

@include pc {
}

/*--------------------tab 768px---------------------*/

@include tab {
}

/*--------------------sp 480px---------------------*/

@include sp {
}
