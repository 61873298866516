@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?moiwxg");
  src: url("fonts/icomoon.eot?moiwxg#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?moiwxg") format("truetype"), url("fonts/icomoon.woff?moiwxg") format("woff"),
    url("fonts/icomoon.svg?moiwxg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail02:before {
  content: "\e906";
}
.icon-download:before {
  content: "\e900";
}
.icon-tab:before {
  content: "\e901";
}
.icon-rigth:before {
  content: "\e902";
}
.icon-tel:before {
  content: "\e903";
}
.icon-mail:before {
  content: "\e904";
}
.icon-under:before {
  content: "\e905";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-folder:before {
  content: "\e92f";
}
.icon-phone-hang-up:before {
  content: "\e943";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-new-tab:before {
  content: "\ea7e";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
