#top {
  #topMv {
    .mvInr {
      background-image: url(../cmn_img/top/mv.png);
      background-size: cover;
      background-attachment: fixed;
      position: relative;
      width: 100%;
      .bkslider {
        li {
          width: 100%;
          max-width: 100%;
          img {
            max-width: 100%;
            width: 100%;
          }
        }
      }
      // .ttlBox {
      //   @include posCenter;
      //   color: #fff;
      //   z-index: 2;
      //   text-align: center;
      //   opacity: 0;
      //   filter: blur(100px);
      //   transition: all 0.8s $easeOutQuad;
      //   .mvImg {
      //     img {
      //       width: 180px;
      //       margin-right: 40px;
      //       margin-bottom: 20px;
      //       @include tab {
      //         width: 150px;
      //       }
      //       @include sp {
      //         margin-right: 30px;
      //         width: 100px;
      //       }
      //     }
      //   }
      //   .mvTxt {
      //     img {
      //       width: 300px;
      //       @include tab {
      //         width: 250px;
      //       }
      //       @include sp {
      //         width: 180px;
      //       }
      //     }
      //   }
      // }

      .newsBox {
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        @include tab {
          position: static;
          width: 100%;
          display: block;
          background-color: rgba(255, 255, 255, 1);
        }
        .title {
          display: inline-block;
          text-align: center;
          line-height: 1.3;
          a {
            display: block;
            background: rgb(0, 104, 183);
            background: linear-gradient(60deg, rgba(0, 104, 183, 1) 0%, rgba(58, 208, 221, 1) 100%);
            font-size: 15px;
            color: #fff;
            padding: 30px;
            @include mintab {
              &:hover {
                opacity: 0.8;
              }
            }
          }
          @include tab {
            display: block;
            width: 100%;
            margin: 0 auto;
            a {
              font-size: 14px;
              padding: 20px 10px;
            }
          }
        }
        .txtBox {
          display: inline-block;
          margin-left: 20px;

          a {
            color: #000;
            time {
              font-size: 15px;
              @include uniq02;
            }
            .txt {
              margin-top: 5px;
              font-size: 15px;
              height: 25px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            @include mintab {
              &:hover {
                color: $c01;
              }
            }
          }
          @include tab {
            display: none;
          }
          // @include tab {
          //   display: block;
          //   width: 90%;
          //   margin: 0 auto;
          //   padding: 20px 0;

          //   a {
          //     time {
          //       font-size: 15px;
          //     }
          //     .txt {
          //       margin-top: 5px;
          //       font-size: 15px;
          //     }
          //   }
          // }
        }
      }
    }
    &.on {
      .mvInr {
        .ttlBox {
          opacity: 1 !important;
          filter: blur(0) !important;
        }
      }
    }
    @include pc {
      .mvInr {
        background-attachment: initial;
      }
    }
  }
  #sec01 {
    background-color: #fff;
    position: relative;
    z-index: 0;
    @include pc {
      padding: 60px 0;
    }
    .sec01Inr {
      position: absolute;
      width: 80%;
      height: 100%;
      background-color: #f5f9fc;
      background: rgb(0, 104, 183);
      background: linear-gradient(90deg, rgba(245, 249, 252, 1) 0%, rgba(253, 243, 246, 1) 100%);
      top: 0;
      right: 0;
      z-index: -1;
    }
    .secTitleBox {
      display: block;
      text-align: right;
      margin-bottom: 30px;
      .subTitle {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        padding-right: 30px;
        @include pc {
          display: none;
        }
      }
      .subTitleSp {
        display: none;
        vertical-align: middle;
        font-size: 16px;
        @include pc {
          display: block;
        }
        @include tab {
          font-size: 14px;
        }
      }
      .head01Inr {
        display: inline-block;
        vertical-align: middle;
        @include pc {
          margin-bottom: 20px;
        }
      }
    }

    .brandList {
      li {
        width: calc(94% / 3);
        float: left;
        margin-right: 3%;
        background-color: #fff;
        padding: 40px 30px;
        @include pc {
          padding: 20px 10px;
        }
        @include tab {
          width: 100%;
          float: none;
          padding: 20px 40px;
          margin-bottom: 30px;
        }
        @include sp {
          padding: 10px 10px;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-right: 0%;
        }
        .logo {
          width: 100%;
          margin-bottom: 30px;
          text-align: center;
          img {
            max-width: 100%;
          }
          @include tab {
            margin-bottom: 10px;
          }
        }
        .titleBox {
          text-align: center;
          padding: 10px 20px;
          font-size: 22px;
          color: #fff;
          margin-bottom: 15px;
          @media (max-width: 1180px) {
            font-size: 18px;
          }
          @include pc {
            font-size: 15px;
            padding: 10px 5px;
          }
          &.c01 {
            background-color: #0068b7;
          }
          &.c02 {
            background-color: #e95283;
          }
          &.c03 {
            background-color: #009944;
          }
        }
        .txt {
          font-size: 16px;
          margin-bottom: 30px;
        }
      }
      // @include mintab {
      //   @for $value from 1 through 3 {
      //     li:nth-child(#{$value}) {
      //       transition-delay: 0.1s + (($value / 3) - 0.1);
      //     }
      //   }
      // }
    }
  }
  #sec02 {
    background-image: url(../cmn_img/top/mv.png);
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    width: 100%;
    .txtBox {
      color: #fff;
      text-align: center;
      position: relative;
      .bkImg {
        @include posCenter;
        top: 40%;
        img {
          max-width: 100%;
          @include tab {
            width: 320px;
          }
        }
      }
      .titleEn {
        font-size: 40px;
        font-style: italic;
        @include baseEn;
        margin-bottom: 20px;
        @include tab {
          font-size: 28px;
        }
      }
      .titleJa {
        font-size: 73px;
        line-height: 1;
        margin-bottom: 30px;
        @include tab {
          font-size: 58px;
        }
        @include sp {
          font-size: 42px;
        }
      }
      .txt {
        line-height: 2;
        margin-bottom: 30px;
      }
      .bscBtn01 {
        @include pc {
          width: 320px;
          margin: 0 auto;
        }
      }
    }
  }
  #sec03 {
    padding: 80px;
    @include tab {
      padding: 60px;
    }
    @include sp {
      padding: 60px 3%;
    }
    .secTitleBox {
      display: block;
      margin-bottom: 60px;
      position: relative;
      .subTitle {
        display: inline-block;
        vertical-align: middle;
        padding-left: 60px;
        @include posCenterY;
        right: 0;
        @include pc {
          bottom: 0;
        }
        @include tab {
          bottom: 100%;
        }
        img {
          max-width: 500px;

          @media (max-width: 1440px) {
            max-width: 480px;
          }
          @media (max-width: 1260px) {
            max-width: 320px;
          }
          @include tab {
            max-width: 300px;
          }
          @include sp {
            max-width: 280px;
          }
        }
      }
      .head01Inr {
        display: inline-block;
        vertical-align: middle;
        .head01 {
          &::before {
            top: -35px;
          }
        }
      }
    }

    .initiativesList {
      margin-bottom: 50px;
      .slide-arrow {
        @include posCenterY;
        width: 60px;
        z-index: 1;
        @include tab {
          width: 50px;
        }
        @include sp {
          width: 30px;
        }
        &.prev-arrow {
          left: -80px;
          @include tab {
            left: -60px;
          }
          @include sp {
            left: -10px;
          }
        }
        &.next-arrow {
          right: -80px;
          @include tab {
            right: -60px;
          }
          @include sp {
            right: -10px;
          }
        }
      }

      li {
        margin-right: 20px;
        background-color: #fff;
        padding: 20px;
        &:last-child {
          margin-right: 0%;
        }
        @include tab {
          margin-right: 0;
        }
        .imgBox {
          background-position: center center;
          background-size: cover;
          margin-bottom: 10px;
          img {
            width: 100%;
          }
        }

        .titleBox {
          margin-bottom: 10px;
          .cat {
            padding: 3px 5px;
            color: #fff;
            font-size: 15px;
            &.cat01 {
              background-color: $catClr01;
            }
            &.cat02 {
              background-color: $catClr02;
            }
            &.cat03 {
              background-color: $catClr03;
            }
          }
          time {
            font-size: 14px;
            padding-left: 10px;
            color: #888888;
            @include uniq02;
          }
        }
        .txt {
          font-size: 16px;
          margin-bottom: 30px;
          height: 75px;
          width: 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .sdgs {
          li {
            float: none;
            display: inline-block;
            vertical-align: bottom;
            padding: 0;
            padding-right: 10px;
            width: 70px;
            margin: 0;
            &.large {
              width: 80px;
            }
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  #sec04 {
    background-image: url("../cmn_img/top/sec04bkg.jpg");
    background-position: center;
    background-size: cover;

    padding: 60px 0;
    line-height: 0;
    position: relative;
    .imgBox {
      text-align: center;
      margin-bottom: 30px;
      img {
        max-width: 80%;
        margin: 0 auto;
        @include tab {
          max-width: 100%;
        }
      }
    }
    .btnLink {
      max-width: 820px;
      margin: 0 auto;
      width: 100%;
      @include tab {
        max-width: 100%;
        width: 100%;
        text-align: center;
      }
      li {
        display: inline-block;
        width: calc(94% / 3);
        margin-right: 3%;
        text-align: center;
        &:nth-child(3) {
          margin-right: 0;
        }
        @include tab {
          width: calc(97% / 2);
          margin-bottom: 20px;
          &:nth-child(2) {
            margin-right: 0;
          }
          &:nth-child(3) {
            width: 60%;
          }
        }

        a {
          display: inline-block;
          width: 100%;
          position: relative;
          @include bscBtn;
          font-size: 15px;
          letter-spacing: 0;
          padding: 15px 0px;
          color: #fff;
          border-radius: 50px;
          @include gradationBg01;
          @include sp {
            font-size: 12px;
          }
          i {
            @include posCenterX;
            left: 30px;
            @include transition;
            @include tab {
              left: 20px;
            }
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  #sec05 {
    background: rgb(207, 232, 248);
    background: linear-gradient(180deg, rgba(207, 232, 248, 1) 0%, rgba(254, 242, 196, 1) 100%);
    .bannerBox {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      li {
        float: left;
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        @include tab {
          float: none;
          width: 100%;
          margin-bottom: 20px;
        }
        img {
          width: 100%;
          max-width: 100%;
          @include transition;
        }
        &:hover {
          img {
            opacity: 0.8;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

/*--------------------pc 1024px---------------------*/

@include pc {
}

/*--------------------tab 768px---------------------*/

@include tab {
}

/*--------------------sp 480px---------------------*/

@include sp {
}
