.editor {
  margin-bottom: 60px;
  img {
    max-width: 100%;
    height: auto;
  }

  p {
    line-height: 2;
    font-size: 18px;
    table-layout: fixed;
    word-wrap: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
    margin-bottom: 1em;
  }

  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      @include gradationBg01;
      @include posCenterX;
      bottom: 0;
    }
  }

  h3 {
    color: #000;
    font-size: 20px;
    padding-left: 1em;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    &::after {
      content: "";
      display: block;
      width: 0.8em;
      height: 3px;
      position: absolute;
      top: 40%;
      transform: translate(-1em, -50%);
      background-color: #009dda;
    }
  }

  ul {
    margin-bottom: 15px;

    li {
      line-height: 170%;
      text-indent: -1em;
      padding-left: 1em;
      margin-bottom: 5px;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        left: 0;
        background-color: #acacac;
        border-radius: 10px;
        margin-right: 0.5em;
      }
    }
  }

  table {
    max-width: 100%;
    width: auto !important;
    @include tab {
      width: 100% !important;
    }
    td,
    th {
      line-height: 2;
      font-size: 18px;
      p {
        table-layout: fixed;
        word-wrap: break-word;
      }
      @include tab {
        display: block;
        width: 100%;
      }
    }
  }

  a {
    text-decoration: underline;
  }

  iframe {
    max-width: 100%;
  }

  .editorImgName {
    text-align: right;
    line-height: 0;
    img {
    }
    p {
      padding: 5px 20px;
      color: #fff;
      line-height: 1.2;
      font-size: 16px;
      text-align: right;
      background-color: $c02;
      display: inline-block;
    }
  }
  .editorPdfLink {
    margin: 10px 0;
    a {
      color: $c01;
      font-weight: 900;
      font-size: 16px;
      img {
        width: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }

  .editorFltImgWrap {
    overflow: hidden;
    margin-bottom: 3em;
  }

  .editorFltImgL {
    float: left;
    margin: 0 25px 20px 0;
  }

  .editorFltImgR {
    float: right;
    margin: 0 0 20px 25px;
  }

  .editorFltImgL,
  .editorFltImgR {
    max-width: 45%;
    margin-bottom: 0;

    @include pc {
      max-width: 100%;
      margin-bottom: 2em;
      text-align: center;
      float: none;
      margin: 0;
      text-align: center;
      img {
        max-width: 100%;
        margin-bottom: 5px;
      }
    }
  }
  .editorBtn01 {
    text-align: center;
    a {
      display: inline-block;
      position: relative;
      @include bscBtn;
      @include gradationBg01;
      font-size: 15px;
      letter-spacing: 0;
      padding: 20px 80px;
      color: #fff;
      border-radius: 50px;
      i {
        @include posCenterX;
        left: 30px;
        @include transition;
      }
      &:hover {
        opacity: 0.8;
      }

      @include pc {
        display: block;
        padding: 15px 20px 13px;
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .editorTbl01 {
    table {
      width: 100% !important;
      th,
      td {
        box-sizing: border-box;
        border-bottom: 1px solid #d1dde2;
        line-height: 1.4;
        padding: 20px 20px 17px;
        text-align: left;
      }

      tr.head th {
        background-color: $c02;
        color: #fff;
        border-right: 1px solid #fff;
        &:nth-child(2) {
          background-color: rgba(0, 157, 218, 0.8);
        }
      }

      tr:last-child {
        th {
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }

  .editorTbl02 {
    table {
      width: 100%;
      @include tab {
        display: block;
      }
      th,
      td {
        vertical-align: top;
        border-bottom: none;
        padding: 0px;
        text-align: left;
        font-size: 18px;
        padding-bottom: 20px;
        @include tab {
          font-size: 14px;
          display: block;
          width: 100%;
        }
      }

      th {
        text-align: center;
        width: 180px;
        color: #fff;
        @include tab {
          width: auto;
          text-align: left;
        }
        &.color01 {
          span {
            padding: 5px 20px;
            border-radius: 25px;
            background-color: $c01;
          }
        }
        &.color02 {
          span {
            padding: 5px 20px;
            border-radius: 25px;
            background-color: $c02;
          }
        }
      }
      td {
        font-size: 16px;
        @include tab {
          font-size: 14px;
          display: block;
          width: 100%;
        }
      }
    }
  }
}
