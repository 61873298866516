.bscBtn01 {
  text-align: center;
  a {
    display: inline-block;
    position: relative;
    @include bscBtn;
    @include gradationBg01;
    font-size: 15px;
    letter-spacing: 0;
    padding: 20px 80px;
    color: #fff;
    border-radius: 50px;
    i {
      @include posCenterX;
      left: 30px;
      @include transition;
    }
    &:hover {
      opacity: 0.8;
    }

    @include pc {
      display: block;
      padding: 15px 20px 13px;
      width: 100%;
      max-width: 100%;
    }
  }
}

.bscBtn01.wNormal {
  max-width: 370px;
  margin: 0 auto;
}

.bscBtn02 a {
  @include bscBtn;
  border: 1px solid #fff;

  &:hover {
    background-color: #fff;
    color: $c01;

    i {
      color: $c01;
    }
  }
}

.uniqBtn01 {
  text-align: center;
  a {
    display: inline-block;
    @include bscBtn;
    background-color: transparent;
    font-size: 11px;
    padding: 20px 0;
    color: #000;
    border: 1px solid #000;
    margin: 0 auto;
    width: 200px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @include pc {
      display: block;
      padding: 20px 5%;
      width: 100%;
      max-width: 100%;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      transition: all 0.3s;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &::after {
      bottom: 0;
      left: 0;
    }
    &::before {
      opacity: 0;
      top: 0;
      right: 0;
      background: rgba(76, 93, 118, 0.6);
      transform: scale(0.1, 1);
    }
    &:hover {
      letter-spacing: 5px;
      color: #fff;
      font-weight: 600;
      &::before {
        opacity: 1;
        transform: scale(1, 1);
      }
      &::after {
        transform: scale(1, 0.1);
        opacity: 0;
      }
      @include tab {
        letter-spacing: 0.2em;
        color: #000;
        font-weight: 500;
        &::before {
          opacity: 0;
        }
        &::after {
          transform: scale(1, 0.1);
          opacity: 0;
        }
      }
    }
  }
}
