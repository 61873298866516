#news {
  .secTitleBox {
    margin-bottom: 60px;
  }
  #main {
    .newsTitle {
      background-color: rgba(255, 255, 255, 0.7);
      padding: 20px 10px;
      margin-bottom: 40px;
      box-shadow: 0px 0px 10px -6px #7d7d7d;
      @include pc {
        padding-bottom: 0px;
      }
      li {
        float: left;
        text-align: center;
        width: 200px;
        margin: 0 10px;
        a {
          font-size: 15px;
          @include transition;
          border-radius: 25px;
          background-color: #d5e1e6;
          display: block;
          padding: 8px 0px;
        }
        &.active {
          a {
            @include gradationBg01;
            color: #fff;
          }
        }
        &:hover {
          a {
            opacity: 0.8;
          }
        }
        @include pc {
          width: calc((100% - 40px) / 2);
          margin-bottom: 20px;
        }
      }
    }
    .newsList {
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 0px 10px -6px #7d7d7d;
      padding: 60px;
      @include tab {
        padding: 30px;
      }
      @include sp {
        padding: 10px;
      }
      li {
        padding: 2em 0;
        border-bottom: 1px solid #d1dde2;
        dl {
          display: table;
          table-layout: fixed;
          width: 100%;
          @include transition;
          &:hover {
            transform: translateX(1em);
          }

          dt {
            display: table-cell;
            vertical-align: middle;
            margin-right: 2em;
            font-size: 0.9em;
            width: 110px;
            time {
              font-size: 16px;
              display: inline-block;
              vertical-align: middle;
              @include uniq02;
              color: #666666;
            }
          }

          dd {
            font-size: 15px;
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      @include pc {
        li {
          dl {
            dt,
            dd {
              vertical-align: top;
            }
          }
        }
      }
      @include tab {
        li {
          dl {
            display: block;

            dt {
              display: block;
            }

            dd {
              display: block;
              margin-top: 1em;
            }
          }
        }
      }
    }
    .detail {
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 0px 10px -6px #7d7d7d;
      padding: 60px;
      @include tab {
        padding: 30px;
      }
      @include sp {
        padding: 30px 10px;
      }
      .detailTitleBox {
        margin-bottom: 30px;
        h2.detailTitle {
          font-size: 30px;
          margin-bottom: 10px;
          font-weight: 400;
          @include tab {
            font-size: 24px;
            margin-bottom: 20px;
          }
        }
        time {
          font-size: 30px;
          display: block;
          @include baseEn;
          margin-bottom: 0px;
          @include tab {
            font-size: 20px;
          }
        }
        span {
          font-size: 22px;
          display: block;
          margin-bottom: 15px;
          @include baseJa;
          @include tab {
            font-size: 20px;
          }
        }
      }
      .contactBox {
        border: 1px solid #d1dde2;
        background-color: #fff;
        padding: 30px;
        margin-bottom: 60px;
        @include sp {
          padding: 10px;
        }
        .contactTitle {
          display: inline-block;
          background-color: #dbe8ee;
          font-size: 16px;
          padding: 8px;
          margin-bottom: 20px;
        }
        .department {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .tel {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 5px;
          @include transition;
          &:hover {
            opacity: 0.8;
          }
        }
        .mail {
          display: block;
          color: #0068b7;
          font-size: 16px;
          font-weight: 600;
          @include transition;
          &:hover {
            opacity: 0.8;
          }
          i {
            font-size: 16px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

/*--------------------pc 1024px---------------------*/

@include pc {
}

/*--------------------tab 768px---------------------*/

@include tab {
}

/*--------------------sp 480px---------------------*/

@include sp {
}
