@charset "utf-8";

@mixin posCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin posCenterY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin posCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin posCenterNone {
  position: static;
  transform: translate(0);
}

@mixin imgOverlay {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @include transition;
}

@mixin tableCell {
  display: table-cell;
  table-layout: fixed;
  vertical-align: middle;
}

@mixin bscBtn {
  font-size: 1.3rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  letter-spacing: 0.2em;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
  padding: 15px 20px 13px;
  // width: 100%;
  line-height: 140%;
  text-decoration: none;
  position: relative;

  i {
    font-size: 1em;
    font-weight: 600;
    position: absolute;
    right: 3%;
    top: 38%;
  }
}
